<template>
    <div>
        <div class="container">
            <div class="navigationMargin">
                <h2 class="pt-4 text-center">Support</h2>
                <div>
                    <h4 class="pt-4">Contact</h4>
                    <p>Ask us anything! About us, about the trip, about the weather. We’re happy to help.</p>
                    <div class="row">
                        <div class="col-12 col-sm-4 pb-3">
                            <h6>MOBILE</h6>
                            <a href="tel:+385917201317">+385 91 720 1317</a>
                        </div>
                        <div class="col-12 col-sm-4 pb-3">
                            <h6>EMAIL</h6>
                            <a href="mailto:office@balkanroads.co">office@balkanroads.co</a>
                        </div>
                        <div class="col-12 col-sm-4 pb-3">
                            <h6>CONNECT</h6>
                            <a href="https://www.facebook.com/BalkanRoads/" target="_blank">
                                <svg focusable="false" data-prefix="fab" data-icon="facebook-f"
                                     class="facebookIcon svg-inline--fa fa-facebook-f fa-w-10" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path fill="currentColor"
                                          d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/balkan_roads/" target="_blank" >
                                <svg focusable="false" data-prefix="fab" data-icon="instagram"
                                     class="instagramIcon svg-inline--fa fa-instagram fa-w-14" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor"
                                          d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <h4 class="pt-4">The Frequently Asked Questions</h4>
                <div class="accordion accordion-flush" id="faqAccordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="false"
                                    aria-controls="flush-collapseOne">
                                What sort of experiences can I have?
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse"
                             aria-labelledby="flush-headingOne" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>100 checkpoints varying from the deliciously extreme to the extremely delicious.</p>
                                <ul>
                                    <li>Bungee jump off one of the highest bridges in Croatia with Vilis</li>
                                    <li>Create your own adventure in Bulgaria with Nasko</li>
                                    <li>Learn traditional Bulgarian knitting and cooking with Deshka</li>
                                    <li>Experience the simple life of a shepherd in Montengro’s highest peak and learn
                                        to produce cheese with Petar
                                    </li>
                                    <li>Raft or zipline through the emerald abyss of Durmitor National park with Dole
                                    </li>
                                    <li>Kite surf Montengro’s stunning beaches with the charismatic Nemanja</li>
                                    <li>Be invited into a true Gypsie community in Romania with Gabriel</li>
                                    <li>Boat down the biodiverse Danube delta river and enjoy an authentic gastronomical
                                        feast
                                    </li>
                                    <li>Zipline through the Pazin cave with Mladen</li>
                                    <li>Discover the mystical and mountainous terrain of Kosovo and take dip in the
                                        breaktaking Blue Eye of Albania
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                    aria-controls="flush-collapseTwo">
                                What countries are included?
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse"
                             aria-labelledby="flush-headingTwo" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>
                                    Croatia (start and finish), Slovenia, Bosnia and Herzegovina, Serbia, Kosovo,
                                    Montenegro, Macedonia, Greece, Bulgaria, Romania, Moldova, Transnistria, Ukraine
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree" aria-expanded="false"
                                    aria-controls="flush-collapseThree">
                                How do I collect points?
                            </button>
                        </h2>
                        <div id="flush-collapseThree" class="accordion-collapse collapse"
                             aria-labelledby="flush-headingThree" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <ul>
                                    <li>Get to the checkpoint</li>
                                    <li>Take a selfie, photo or video of you doing the thing</li>
                                    <li>Post it in the Facebook group as proof or on insta with the hashtag <strong>#BalkanRoads
                                        @BalkanRoads *** </strong></li>
                                    <li>The value of each checkpoint will be listed in the instructions</li>
                                    <li>Points are collected individually i.e. you can’t mooch off your friend</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour" aria-expanded="false"
                                    aria-controls="flush-collapseFour">
                                What is the duration of the road-trip?
                            </button>
                        </h2>
                        <div id="flush-collapseFour" class="accordion-collapse collapse"
                             aria-labelledby="flush-headingFour" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>
                                    It can be endless if you so choose. Depending on the number of checkpoints you want
                                    to
                                    experience, how fast or slow you tend to travel, the number of countries you want to
                                    cross, you could do it in a week or a month. We’re suggesting 2.5 weeks with the
                                    opening
                                    and closing parties at either end.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive" aria-expanded="false"
                                    aria-controls="flush-collapseTFive">
                                What are the prizes?
                            </button>
                        </h2>
                        <div id="flush-collapseFive" class="accordion-collapse collapse"
                             aria-labelledby="flush-headingFive" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>
                                    First place: <strong>Drone</strong> <br>
                                    Second place: <strong>GoPro</strong> <br>
                                    The prizes are activated with a minimum of 20 registrations. Terms and conditions
                                    apply – <router-link :to="{name: 'settings.TermsAndConditions'}" class="link-info">read them.</router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingSix">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSix" aria-expanded="false"
                                    aria-controls="flush-collapseSix">
                                Do you give me any free stuff?
                            </button>
                        </h2>
                        <div id="flush-collapseSix" class="accordion-collapse collapse"
                             aria-labelledby="flush-headingSix" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>We’re glad you asked you cheapskates! Included in the cost of your ticket are the
                                    following complimentary gifts:</p>
                                <ul>
                                    <li>Three complimentary nights of accommodation at selected locations</li>
                                    <li>Two pit-stop BBQ’s with food and drink covered</li>
                                    <li>Entry to opening and closing party with never-ending happy hour</li>
                                    <li>A Balkan survival kit with awesome croatian things like Rakia</li>
                                    <li>The greatest souvenir – a personal diary with the summary of your route</li>
                                    <li>Two vogue-worthy Balkan Roads T-shirts</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingSeven">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSeven" aria-expanded="false"
                                    aria-controls="flush-collapseSeven">
                                Tell me about the safety stuff
                            </button>
                        </h2>
                        <div id="flush-collapseSeven" class="accordion-collapse collapse"
                             aria-labelledby="flush-headingSeven" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <ul>
                                    <li><strong>GPS</strong> <br>
                                        Your car will be kitted out with a GPS tracker – so we know where you are and
                                        can text
                                        you hints, tips and interesting facts to enrich your experience. It’s your
                                        decision if
                                        you turn off it. We don’t recommend it. But it’s always up to you.
                                    </li>
                                    <li><strong>Insurance</strong></li>
                                    <li><strong>Visas</strong></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingEight">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseEight" aria-expanded="false"
                                    aria-controls="flush-collapseEight">
                                Is this a banger rally?
                            </button>
                        </h2>
                        <div id="flush-collapseEight" class="accordion-collapse collapse"
                             aria-labelledby="flush-headingEight" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>
                                    No. This is not a rally or race. This is one of the most atypical, audacious,
                                    ridiculous
                                    and soul-inspiring road-trips you will ever go on. We encourage the Yugo as your
                                    road-trip vehicle because this about full cultural immersion and we promise you,
                                    when
                                    you need to barter or bargain with a local, having the Yugo on your side will only
                                    help
                                    your cause.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingNine">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseNine" aria-expanded="false"
                                    aria-controls="flush-collapseNine">
                                What happens if my car breaks down?
                            </button>
                        </h2>
                        <div id="flush-collapseNine" class="accordion-collapse collapse"
                             aria-labelledby="flush-headingNine" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                The point of this adventure is to make it through all the challenges – the planned and
                                the unplanned. Towing can be arranged BUT if we come out to tow you, you lose all your
                                points gained to date. Remember, it’s how you handle the unplanned moments that become
                                your greatest stories.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTen">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTen" aria-expanded="false"
                                    aria-controls="flush-collapseTen">
                                Do I have to start in Rijeka?
                            </button>
                        </h2>
                        <div id="flush-collapseTen" class="accordion-collapse collapse"
                             aria-labelledby="flush-headingTen" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>
                                    Nothing in life is mandatory except for paying taxes and dying. However if you don’t
                                    start in Rijeka, you’ll miss the kick-off and closing celebrations and the chance to
                                    connect with your fellow adventurists. If you’re renting a Yugo from us then
                                    obviously you’ll need to be in Rijeka to pick up. All other elements we can arrange
                                    to have sent to you (postage fees may apply). Rijeka is also the best starting point
                                    for your drive down the ridiculously beautiful Adriatic Coast.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingEleven">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseEleven" aria-expanded="false"
                                    aria-controls="flush-collapseEleven">
                                Can I bring my own vehicle? And decorate it?
                            </button>
                        </h2>
                        <div id="flush-collapseEleven" class="accordion-collapse collapse"
                             aria-labelledby="flush-headingEleven" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                We’re going to remain patient just in case you haven’t read any of the other pages. So
                                here it goes: We can’t force you to rent a Yugo. You can bring your own car with all its
                                airconditioned comfort. But that’ll automatically deduct 100 points from your checkpoint
                                tally. Because that just ain’t the way we do it here in the Balkans and if you’re
                                signing up for this adventure then why not commit to doing it right. A Yugo will cost
                                you €1000 for the two weeks. It’ll be the raddest €1000 you’ve ever spent. IF we still
                                haven’t convinced you to do this in a Yugo, yes you can bring your own car and yes you
                                can decorate in whatever way you choose as long as it’s not lewd, disrespectful or
                                breaking any laws.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
@import "../../../scss/variables.scss";

.navigationMargin {
    @media screen and (min-width: 768px) {
        margin-left: 80px;
    }

    h6 {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        color: #c1272d;
        margin-bottom: 2px;
    }

    a {
        color: #707070;
        text-decoration: none;

        .facebookIcon{
            width: 14px;
            margin-right: 20px;
        }
        .instagramIcon{
            width: 20px;
        }
    }

    .accordion {
        margin-top: 20px;
        padding-bottom: 110px;

        @media only screen and (min-width: 768px) {
            padding-bottom: 50px;
        }

        .accordion-header {
            font-family: "Roboto", sans-serif;

            button {
                padding: 1rem 0.3rem;
            }
        }

        .accordion-button:focus {
            z-index: 3;
            border: 1px solid $primary;
            outline: 0;
            box-shadow: none;
        }

        .accordion-button:not(.collapsed) {
            color: $secondary;
            background-color: transparent;
        }
    }
}

</style>
